var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-single-fragment third-party-dlg",staticStyle:{"position":"relative"}},[_c('el-form',{ref:"form",staticClass:"full-width-input form-box",staticStyle:{"width":"100%","overflow-y":"auto","overflow-x":"hidden"},attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"100px","size":_vm.defaultFormItemSize,"label-position":"right"},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"按钮类型","prop":"type"}},[_c('el-select',{staticClass:"input-item",attrs:{"clearable":true,"placeholder":"按钮类型"},on:{"change":_vm.onOperationTypeChange},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}},_vm._l((_vm.SysFlowTaskOperationType.getList().filter(
                (item) => item.id !== _vm.SysFlowTaskOperationType.INTERVENE
              )),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"按钮名称","prop":"label"}},[_c('el-input',{staticClass:"input-item",attrs:{"clearable":true,"placeholder":"按钮名称"},model:{value:(_vm.formData.label),callback:function ($$v) {_vm.$set(_vm.formData, "label", $$v)},expression:"formData.label"}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"更新工单状态"}},[_c('el-select',{staticClass:"input-item",model:{value:(_vm.formData.latestApprovalStatus),callback:function ($$v) {_vm.$set(_vm.formData, "latestApprovalStatus", $$v)},expression:"formData.latestApprovalStatus"}},[_c('el-option',{attrs:{"label":"不更新","value":undefined}}),_vm._l((_vm.dialogParams.validStatusLiist),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})})],2)],1)],1),(
          _vm.formData.type === _vm.SysFlowTaskOperationType.MULTI_SIGN ||
          _vm.formData.type === _vm.SysFlowTaskOperationType.SET_ASSIGNEE
        )?_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":(_vm.formData.type === _vm.SysFlowTaskOperationType.MULTI_SIGN
              ? '会签'
              : '审批') + '用户类型'}},[_c('el-select',{attrs:{"placeholder":""},on:{"change":_vm.typeChange},model:{value:(_vm.multiSignAssignee.assigneeType),callback:function ($$v) {_vm.$set(_vm.multiSignAssignee, "assigneeType", $$v)},expression:"multiSignAssignee.assigneeType"}},[_c('el-option',{attrs:{"label":"用户","value":"USER_GROUP"}}),_c('el-option',{attrs:{"label":"角色","value":"ROLE_GROUP"}}),_c('el-option',{attrs:{"label":"部门","value":"DEPT_GROUP"}}),_c('el-option',{attrs:{"label":"岗位","value":"POST_GROUP"}}),_c('el-option',{attrs:{"label":"部门岗位","value":"DEPT_POST_GROUP"}})],1)],1)],1):_vm._e(),(
          _vm.formData.type === _vm.SysFlowTaskOperationType.MULTI_SIGN ||
          _vm.formData.type === _vm.SysFlowTaskOperationType.SET_ASSIGNEE
        )?_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":(_vm.formData.type === _vm.SysFlowTaskOperationType.MULTI_SIGN
              ? '会签'
              : '审批') + '用户选择'}},[(_vm.multiSignAssignee.assigneeType === 'USER_GROUP')?_c('TagSelect',{model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}},[_c('el-button',{staticClass:"append-add",attrs:{"slot":"append","type":"default","icon":"el-icon-plus"},on:{"click":_vm.onSelectUser},slot:"append"})],1):_vm._e(),(
              _vm.multiSignAssignee.assigneeType === 'ROLE_GROUP' ||
              _vm.multiSignAssignee.assigneeType === 'POST_GROUP'
            )?_c('el-select',{staticClass:"assignee-select",attrs:{"placeholder":"","multiple":true},model:{value:(_vm.multiSignAssignee.assigneeList),callback:function ($$v) {_vm.$set(_vm.multiSignAssignee, "assigneeList", $$v)},expression:"multiSignAssignee.assigneeList"}},_vm._l((_vm.multiSignGroupList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1):_vm._e(),(_vm.multiSignAssignee.assigneeType === 'DEPT_GROUP')?_c('TagSelect',{model:{value:(_vm.deptItems),callback:function ($$v) {_vm.deptItems=$$v},expression:"deptItems"}},[_c('el-button',{staticClass:"append-add",attrs:{"slot":"append","type":"default","icon":"el-icon-plus"},on:{"click":_vm.onSelectDept},slot:"append"})],1):_vm._e(),(_vm.multiSignAssignee.assigneeType === 'DEPT_POST_GROUP')?_c('el-cascader',{key:"dept_post_select",attrs:{"options":_vm.multiSignGroupList,"props":{
              multiple: true,
              value: 'id',
              label: 'name',
            }},model:{value:(_vm.multiSignAssignee.assigneeList),callback:function ($$v) {_vm.$set(_vm.multiSignAssignee, "assigneeList", $$v)},expression:"multiSignAssignee.assigneeList"}}):_vm._e()],1)],1):_vm._e(),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"显示顺序"}},[_c('el-input-number',{staticClass:"input-item number-center",attrs:{"clearable":true,"placeholder":"显示顺序"},model:{value:(_vm.formData.showOrder),callback:function ($$v) {_vm.$set(_vm.formData, "showOrder", $$v)},expression:"formData.showOrder"}})],1)],1)],1)],1),_c('el-row',{staticClass:"no-scroll flex-box menu-box",staticStyle:{"padding-top":"10px"},attrs:{"type":"flex","justify":"end"}},[_c('el-button',{attrs:{"size":_vm.defaultFormItemSize,"plain":true},on:{"click":function($event){return _vm.onCancel(false)}}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"type":"primary","size":_vm.defaultFormItemSize},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" 保存 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }