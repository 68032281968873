<template>
  <div class="m-b-10">
    <div class="product-item handler rela" @click="showProductDetail">
      <div class="flex">
        <el-image lazy class="images m-r-10" :src="ProductMainImg"></el-image>
        <div class="flex-1 flex-col flex-between p-10">
          <div class="product-title">{{ product.merchandiseName }}</div>
          <el-row>
            <el-col :span="12">
              <span class="money">￥{{ Number(product.merSaleUnitPrice || 0).toFixed(2) }}</span>
            </el-col>
          </el-row>
          <el-row  class="flex align-center">
            <el-col :span="12">
            </el-col>
          </el-row>
        </div>
      </div>
      <el-tag size="mini" style="font-size: 12px;" class="tags">{{ product.brandName }}</el-tag>

      <el-row class="flex flex-between">
        <el-col style="color: #999;">{{ product.supplierName }}</el-col>
        <el-checkbox v-model="isChecked" v-if="checkable" @change="onCheckChange" @click.stop.native="()=>{}"></el-checkbox>
        <i class="el-icon-delete" style="line-height: 2rem;" v-else @click="deleteSelf"></i>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductItem',
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    checkable: {
      type: Boolean,
      default: false
    },
    check: {
      type: Boolean,
      default: false
    },
    /** 删除按钮是否可用 */
    showdel: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isChecked: false
    }
  },
  computed: {
    ProductMainImg () {
      if (!this.product.imageUrls) return ''
      const [main] = this.product.imageUrls
      return main.url
    }
  },
  created () {
    this.isChecked = this.check
  },
  methods: {
    openDetail () {
      this.isOpenDetail = !this.isOpenDetail;
    },
    handleClose (done) {
      done();
    },
    handleClick (tab, event) {
      console.log(tab, event);
    },
    onCheckChange (e) {
      this.$emit('onChange', this.product.merchandiseId, e)
    },
    deleteSelf () {
      this.$emit('remove', this.product.merchandiseId)
    },
    showProductDetail () {
      this.$emit('showDtl', this.product)
    }
  }
}
</script>

<style lang="scss" scoped>
.images{
  border-radius: 20px;
  width: 80px;
  height: 80px;
}
.product-item {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  padding: 10px;
  border-radius: 10px;
  line-height: 2;
  .option-btn{
    color: rgba(24, 144, 255, 1);

  }
  .hanlder{
    cursor: pointer;
  }
}
.money {
  color: #FF0000;
}
.product-title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 48px;
  }
.font-12{
  font-size: 12px;
}
.tags{
  position: absolute;
  left: 4px;
  top: 4px;
}
.rela{
  position: relative;
}
</style>
