<template>
  <div class="form-single-fragment" style="position: relative;">
      <div class="flex form-box">
        <el-form ref="metaInfo" style="width: 100%" :model="formData.MccBatchProduct" label-width="60px" label-position="right">
          <div class="flex flex-between">
            <div class="flex ">
              <el-form-item label="审批单号">
                <el-input class="filter-item" :clearable="true" placeholder="审批单号" v-model="formData.MccBatchProduct.batchNo"
                  disabled />
              </el-form-item>
              <el-form-item label="备注" style="width: 400px;">
                <el-input placeholder="请输入内容" v-model="formData.MccBatchProduct.remark" :disabled="inputDisabled"/>
              </el-form-item>
            </div>
            <el-form-item v-if="showButton">
              <el-button type="primary" @click="addProducts">添加产品</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="m-t-10 p-10 bg-white flex-1 product-wrapper">
        <el-row :gutter="10" style="overflow:auto">
          <el-col :span="4" v-for="item in formData.MccBatchProduct.mmcMerchandiseTProductList" :key="item.merchandiseId">
            <ProductItem :product="item" :showdel="showDelButton" @remove="deleteProduct" @showDtl="showDtl"></ProductItem>
          </el-col>
        </el-row>
      </div>
      <el-form ref="firstPage" :model="formData" class="full-width-input" :disable="readOnly" style="width: 100%;"
        label-width="120px" :size="defaultFormItemSize" label-position="right" @submit.native.prevent>
        <el-row :gutter="20">
        </el-row>
      </el-form>
      <ProductDetail ref="ProductDetail"></ProductDetail>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

/* eslint-disable-next-line */
import { MccBatchProductController } from '@/api';
import { FlowOperationController } from '@/api/FlowController';
/* eslint-disable-next-line */
import { statsDateRangeMixin,uploadMixin } from '@/core/mixins';

/* eslint-disable-next-line */
/* eslint-disable-next-line */
import flowMixins from '../../mixins/flowMixins.js';
import ProductDetail from '../components/ProductDetail.vue'
import ProductItem from '../components/ProductItem.vue';
import SelectProduct from './selectedProduct'
const processDefinitionKey = 'cpsp';

export default {
  name: 'firstPage',
  components: {
    ProductItem,
    ProductDetail
  },
  props: {
    // 是否运行时任务
    isRuntimeTask: {
      type: [Boolean, String]
    },
    // 是否草稿
    isDraft: {
      type: [Boolean, String],
      default: false
    },
    // 是否只读
    readOnly: {
      type: Boolean,
      default: false
    },
    // 流程实例id
    processInstanceId: {
      type: String
    },
    // 抄送消息id
    messageId: {
      type: String
    },
    // 当前任务实例id
    taskId: {
      type: String
    },
    // 任务变量列表
    taskVariableList: {
      type: Array
    }
  },
  mixins: [uploadMixin, statsDateRangeMixin, flowMixins],
  data () {
    return {
      formData: {
        MccBatchProduct: {
          batchNo: undefined,
          category: undefined,
          flowStatus: undefined,
          latestApprovalStatus: undefined,
          remark: undefined,
          gmtCreate: undefined,
          gmtModified: undefined,
          createUid: undefined,
          modifiedUid: undefined,
          deletedFlag: undefined,
          id: undefined,
          mmcMerchandiseTProductList: [],
          isDatasourceInit: false
        }
      },
      mmcMerchandiseTProductDtoList: [],
      // 保存草稿后返回的taskId
      draftTaskId: undefined,
      // 保存草稿后返回的流程实例ID
      draftProcessInstanceId: undefined,
      firstPage: {
        isInit: false
      },
      checkAll: false,
      isIndeterminate: true,
      showButton: false, // 是否显示添加产品按钮
      showDelButton: false, // 是否显示产品的删除按钮
      inputDisabled: false // 备注是否可输入
    }
  },
  methods: {
    showDtl (product) {
      this.$refs.ProductDetail.open(product)
    },
    deleteProduct (id) {
      const index = this.formData.MccBatchProduct.mmcMerchandiseTProductList.findIndex(m => m.merchandiseId === id)
      this.formData.MccBatchProduct.mmcMerchandiseTProductList.splice(index, 1)
    },
    addProducts () {
      this.$dialog.show('添加产品', SelectProduct, {
        area: ['90vw', '95vh']
      },
      {
        currentSelected: this.formData.MccBatchProduct.mmcMerchandiseTProductList.map(m => m.merchandiseId)
      }).then(res => {
        this.formData.MccBatchProduct.mmcMerchandiseTProductList = res
      })
    },
    // 启动流程
    handlerStart (operation, copyItemList, xml) {
      // 启动并保存草稿后再次提交
      if (this.draftProcessInstanceId != null && this.draftTaskId != null) {
        return this.handlerOperation(operation, copyItemList);
      }
      return new Promise((resolve, reject) => {
        this.$refs.firstPage.validate(valid => {
          if (!valid) return reject();
          this.preHandlerOperation(operation, true, xml).then(res => {
            let formData = this.getMasterData(operation.type, (res || {}).assignee);
            return MccBatchProductController.startAndTakeUserTaskCpsp(this, {
              processDefinitionKey: processDefinitionKey,
              taskVariableData: {
                ...formData.taskVariableData,
                latestApprovalStatus: operation.latestApprovalStatus
              },
              flowTaskCommentDto: {
                approvalType: operation.type
              },
              mccBatchProductDto: {
                ...formData.masterData
              },
              ...formData.relationData,
              mmcMerchandiseTProductDtoList: this.formData.MccBatchProduct.mmcMerchandiseTProductList.map(m => m.merchandiseId),
              copyData: (copyItemList || []).reduce((retObj, item) => {
                retObj[item.type] = item.id;
                return retObj;
              }, {})
            });
          }).then(res => {
            this.$message.success('启动成功！');
            resolve();
          }).catch(e => {
            reject(e);
          });
        });
      });
    },
    // 流程操作
    handlerOperation (operation, copyItemList, xml) {
      return new Promise((resolve, reject) => {
        this.$refs.firstPage.validate(valid => {
          if (!valid) return reject();
          this.preHandlerOperation(operation, this.isDraft, xml).then(res => {
            // 加签、减签操作
            if (operation.type === this.SysFlowTaskOperationType.CO_SIGN || operation.type === this.SysFlowTaskOperationType.SIGN_REDUCTION) {
              return this.submitConsign((res || {}).assignee, operation.type === this.SysFlowTaskOperationType.CO_SIGN);
            }
            // 自由跳
            if (operation.type === this.SysFlowTaskOperationType.FREE_JUMP) {
              return FlowOperationController.freeJump(this, {
                processInstanceId: this.processInstanceId,
                sourceTaskId: this.taskId,
                targetTaskKey: (res || {}).targetTaskKey,
                delegateAssignee: (res || {}).assignee,
                taskComment: (res || {}).message,
                taskVariableData: {
                  latestApprovalStatus: operation.latestApprovalStatus
                }
              });
            }
            // 驳回操作
            if (operation.type === this.SysFlowTaskOperationType.REJECT || operation.type === this.SysFlowTaskOperationType.REJECT_TO_TASK) {
              return FlowOperationController.rejectRuntimeTask(this, {
                processInstanceId: this.processInstanceId,
                taskId: this.taskId,
                targetTaskKey: (res || {}).targetTaskKey,
                taskComment: (res || {}).message,
                taskVariableData: {
                  latestApprovalStatus: operation.latestApprovalStatus
                }
              });
            }
            // 驳回到起点
            if (operation.type === this.SysFlowTaskOperationType.REJECT_TO_START) {
              return FlowOperationController.rejectToStartUserTask(this, {
                processInstanceId: this.processInstanceId,
                taskId: this.taskId,
                taskComment: (res || {}).message,
                taskVariableData: {
                  latestApprovalStatus: operation.latestApprovalStatus
                }
              });
            }
            // 撤销操作
            if (operation.type === this.SysFlowTaskOperationType.REVOKE) {
              this.$confirm('是否撤销此任务？').then(res => {
                return FlowOperationController.rejectHistoricTask(this, {
                  processInstanceId: this.processInstanceId,
                  taskId: this.taskId,
                  taskComment: '任务处理人撤销任务',
                  taskVariableData: {
                    latestApprovalStatus: operation.latestApprovalStatus
                  }
                });
              }).catch(e => {
                return Promise.reject(e);
              });
            }
            let formData = this.getMasterData(operation.type, (res || {}).assignee);
            let params = {
              taskId: this.taskId || this.draftTaskId,
              processInstanceId: this.processInstanceId || this.draftProcessInstanceId,
              flowTaskCommentDto: {
                taskComment: (res || {}).message,
                approvalType: operation.type,
                delegateAssignee: operation.type === this.SysFlowTaskOperationType.TRANSFER ? (res || {}).assignee : undefined
              },
              taskVariableData: {
                ...formData.taskVariableData,
                latestApprovalStatus: operation.latestApprovalStatus
              },
              mmcMerchandiseTProductDtoList: this.formData.MccBatchProduct.mmcMerchandiseTProductList.map(m => m.merchandiseId),
              copyData: (copyItemList || []).reduce((retObj, item) => {
                retObj[item.type] = item.id;
                return retObj;
              }, {})
            }
            // 非只读状态下，提交修改数据
            if (!this.readOnly) {
              // 主表数据
              params.mccBatchProductDto = {
                ...formData.masterData
              };
              // 关联数据
              params = {
                ...params,
                ...formData.relationData
              };
            }

            return MccBatchProductController.submitUserTaskCpsp(this, params);
          }).then(res => {
            this.reloadMessage(this);
            if (operation.type !== this.SysFlowTaskOperationType.CO_SIGN && operation.type !== this.SysFlowTaskOperationType.SIGN_REDUCTION) {
              this.$message.success(operation.label + '成功！');
            }
            resolve();
          }).catch(e => {
            console.log(e);
          });
        });
      });
    },
    // 保存草稿
    handlerDraft () {
      return new Promise((resolve, reject) => {
        let formData = this.getFormDataImpl();
        let params = {
          processDefinitionKey: processDefinitionKey,
          processInstanceId: this.processInstanceId || this.draftProcessInstanceId,
          mccBatchProductDto: {
            ...formData.masterData
          },
          ...formData.relationData,
          mmcMerchandiseTProductDtoList: this.formData.MccBatchProduct.mmcMerchandiseTProductList.map(m => m.merchandiseId)
        }
        MccBatchProductController.startAndSaveDraftCpsp(this, params).then(res => {
          this.$message.success('草稿保存成功！');
          this.draftProcessInstanceId = res.data.processInstanceId;
          this.draftTaskId = res.data.taskId;
          resolve();
        }).catch(e => {
          reject(e);
        });
      });
    },
    // 获取表单数据
    getFormDataImpl () {
      // 一对一、一对多、多对多关联数据
      let relationData = {};

      return {
        masterData: {
          ...this.formData.MccBatchProduct
        },
        relationData: relationData
      }
    },
    // 获取流程表单数据
    getMasterData (operationType, assignee) {
      let taskVariableData;
      if (Array.isArray(this.taskVariableList) && this.taskVariableList.length > 0) {
        taskVariableData = this.taskVariableList.reduce((retObj, item) => {
          retObj[item.variableName] = this.formData.MccBatchProduct[item.variableName] || '';
          return retObj;
        }, {});
      }
      // 会签操作设置多实例处理人集合
      assignee = (assignee && assignee !== '') ? assignee.split(',') : undefined;
      if (operationType === this.SysFlowTaskOperationType.MULTI_SIGN) {
        if (taskVariableData == null) taskVariableData = {};
        taskVariableData.assigneeList = assignee;
      } else if (operationType === this.SysFlowTaskOperationType.SET_ASSIGNEE) {
        if (taskVariableData == null) taskVariableData = {};
        taskVariableData.appointedAssignee = Array.isArray(assignee) ? assignee.join(',') : undefined;
      }

      return {
        ...this.getFormDataImpl(),
        taskVariableData
      }
    },
    // 初始化流程表单
    initFormData () {
      if (!this.isStartFlow) {
        // 流程办理
        let params = {
          processInstanceId: this.processInstanceId,
          taskId: this.taskId
        }
        let httpCall = null;
        if (this.isDraft || this.isDraft === 'true') {
          // 草稿数据
          httpCall = FlowOperationController.viewDraftData(this, {
            processDefinitionKey: processDefinitionKey,
            processInstanceId: this.processInstanceId
          });
        } else if (this.messageId != null) {
          // 抄送消息
          httpCall = FlowOperationController.viewCopyBusinessData(this, {
            messageId: this.messageId
          });
        } else {
          httpCall = (this.taskId && this.isRuntime) ? MccBatchProductController.viewTaskBusinessDataCpsp(this, params) : MccBatchProductController.viewHistoricTaskBusinessDataCpsp(this, params)
        }
        httpCall.then(res => {
          this.formData.MccBatchProduct = { ...res.data };
          console.log(this.formData)
          // 获取一对多、多对多数据
          this.refreshFirstPage(true);
        }).catch(e => { });
      } else {
        // 启动流程
      }
    },
    onCancel (isSuccess) {
      if (this.observer != null) {
        this.observer.cancel(isSuccess);
      }
    },
    /**
     * 更新产品审批首页
     */
    refreshFirstPage (reloadData = false) {
      if (!this.firstPage.isInit) {
        // 初始化下拉数据
      }
      this.firstPage.isInit = true;
    },
    formInit () {
      this.initFormData();
    },
    ...mapActions(['reloadMessage'])
  },
  computed: {
    isStartFlow () {
      return this.processInstanceId == null;
    },
    isRuntime () {
      return typeof this.isRuntimeTask === 'string' ? this.isRuntimeTask === 'true' : this.isRuntimeTask;
    }
  },
  mounted () {
    // 初始化页面数据
    this.formInit();
  },
  created () {
    const {taskKey} = this.$route.query
    this.doUrl('/admin/flow/flowOnlineOperation/routeFormAuthList', 'post', {
      processDefinitionKey: 'cpsp',
      taskKey: taskKey
    }).then(res => {
      // 仅本实例 有效
      const status = res.data.find(m => m.wigetName === '添加产品').status
      this.showButton = status === 1
      const delStatus = res.data.find(m => m.wigetName === '添加产品').status
      this.showDelButton = delStatus === 1
      const input = res.data.find(m => m.wigetName === '备注').status
      this.inputDisabled = input === 2
    })
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-form-item {
  margin-bottom: 0;
}
.form-box {
  // background-color: white;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}
</style>
