<template>
  <!-- 历史任务 -->
  <div style="position: relative" class="flex-col">
    <el-form ref="formMyHistoryTask" :model="formFilter" label-width="100px" :size="defaultFormItemSize"
      label-position="right" @submit.native.prevent>
      <filter-box :item-width="350" @search="refreshFormMyHistoryTask(true)" @reset="onReset">
        <el-form-item label="流程名称" prop="processDefinitionName">
          <el-input class="filter-item" v-model="formFilter.processDefinitionName" :clearable="true" placeholder="流程名称" />
        </el-form-item>
        <el-form-item label="发起时间" prop="createDate">
          <date-range class="filter-item" v-model="formFilter.createDate" :clearable="true" :allowTypes="['day']"
            align="left" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss" />
        </el-form-item>
        <el-form-item label="注释1" prop="keyOne">
          <el-input class="filter-item" v-model="formFilter.keyOne"
          clearable placeholder="注释1" />
        </el-form-item>
        <el-form-item label="是否已完成" prop="isFinished">
          <el-select v-model="formFilter.isFinished" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
      </filter-box>
    </el-form>
    <table-box ref="teacher" min-height="500" :data="formMyHistoryTaskWidget.dataList" :size="defaultFormItemSize"
      @sort-change="formMyHistoryTaskWidget.onSortChange" :hasExtend="false">
      <vxe-column title="序号" header-align="center" align="center" type="seq" width="55"
        :index="formMyHistoryTaskWidget.getTableIndex" />
      <vxe-column title="工单编号" field="workOrderCode" />
      <vxe-column title="流程名称" field="processDefinitionName" />
      <vxe-column title="流程标识" field="processDefinitionKey" />
      <vxe-column title="注释1" field="keyword1"/>
      <vxe-column title="注释2" field="keyword2"/>
      <vxe-column title="注释3" field="keyword3"/>
      <vxe-column title="发起人昵称" field="showName" />
      <vxe-column title="任务发起时间" field="startTime">
        <template #default="{row}">
          <Time-Format :time="row.startTime" type="1" v-if="row.startTime" :key="row.startTime"></Time-Format>
        </template>
      </vxe-column>
      <vxe-column title="任务结束时间" field="endTime">
        <template #default="{row}">
          <Time-Format :time="row.endTime" type="1" v-if="row.endTime" :key="row.endTime"></Time-Format>
        </template>
      </vxe-column>
      <vxe-column title="操作" width="100px">
        <template #default="{row}">
          <el-button :size="defaultFormItemSize" type="text" @click="printIfCan(row)">打印</el-button>
          <el-button class="table-btn success" :size="defaultFormItemSize" type="text"
            @click="onFlowDetail(row)">详情</el-button>
        </template>
      </vxe-column>
    </table-box>
    <el-row type="flex" justify="end" style="margin-top: 10px">
      <el-pagination :total="formMyHistoryTaskWidget.totalCount" :current-page="formMyHistoryTaskWidget.currentPage"
        :page-size="formMyHistoryTaskWidget.pageSize" :page-sizes="[10, 20, 50, 100]"
        layout="total, prev, pager, next, sizes" @current-change="formMyHistoryTaskWidget.onCurrentPageChange"
        @size-change="formMyHistoryTaskWidget.onPageSizeChange">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import '@/staticDict/flowStaticDict.js'

import { FlowOperationController } from '@/api/FlowController'
import { fetchDownloadBlob } from '@/core/http';
/* eslint-disable-next-line */
import { cachePageMixin,statsDateRangeMixin, uploadMixin } from '@/core/mixins'
/* eslint-disable-next-line */
import { TableWidget } from '@/utils/widget.js'

export default {
  name: 'formMyHistoryTask',
  props: {},
  mixins: [uploadMixin, statsDateRangeMixin, cachePageMixin],
  data () {
    return {
      formFilter: {
        processDefinitionName: undefined,
        createDate: [],
        isFinished: undefined,
        keyOne: undefined
      },
      formFilterCopy: {
        processDefinitionName: undefined,
        createDate: [],
        isFinished: undefined,
        keyOne: undefined
      },
      formMyHistoryTaskWidget: new TableWidget(this.loadMyHistoryTaskData, this.loadMyHistoryTaskVerify, true, false),
      isInit: false,
      options: [
        {
          value: true,
          label: '已完成'
        },
        {
          value: false,
          label: '进行中'
        }
      ]
    }
  },
  methods: {
    printIfCan ({processDefinitionKey, workOrderId, pageId}) {
      this.doUrl(`/admin/flow/flowOnlineOperation/printWorkOrderV2/${processDefinitionKey}`, 'POST', {
        pageId,
        printParams: [
          [
            {
              paramName: 'workOrderId',
              paramValue: workOrderId
            }
          ]
        ]
      }).then(res => {
        if (res.data) {
          const downloadUrl = res.data
          fetchDownloadBlob(downloadUrl, {}, 'get', 'application/pdf').then(blobData => {
            try {
              let pdfUrl = window.URL.createObjectURL(blobData);
              window.open(pdfUrl, '_blank');
              // 如果立刻销毁 会导致 Safir 浏览器弹出窗 打开之前就无法使用 因此临时解决办法是 延迟销毁
              setTimeout(() => {
                window.URL.revokeObjectURL(pdfUrl);
              }, 15000);
            } catch (error) {
              this.$message.error(error);s
            }
          }).catch(e => {
            this.$message.error(e);
          });
        }
      })
    },
    onReset () {
      this.$refs.formMyHistoryTask.resetFields()
      this.refreshFormMyHistoryTask(true)
    },
    /**
     * 获取所有流程实例
     */
    loadMyHistoryTaskData (params) {
      if (params == null) params = {}
      params = {
        ...params,
        processDefinitionName: this.formFilterCopy.processDefinitionName,
        beginDate: this.formFilterCopy.createDate[0],
        endDate: this.formFilterCopy.createDate[1],
        isFinished: this.formFilterCopy.isFinished,
        keyOne: this.formFilterCopy.keyOne
      }

      return new Promise((resolve, reject) => {
        FlowOperationController.listMyInitiative(this, params)
          .then((res) => {
            let list = res.data.dataList
            list.forEach(m => {
              Object.assign(m, m.formExtField)
            })
            resolve({
              dataList: list,
              totalCount: res.data.totalCount
            })
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    loadMyHistoryTaskVerify () {
      this.formFilterCopy.processDefinitionName = this.formFilter.processDefinitionName
      this.formFilterCopy.createDate = Array.isArray(this.formFilter.createDate) ? [...this.formFilter.createDate] : []
      this.formFilterCopy.isFinished = this.formFilter.isFinished
      this.formFilterCopy.keyOne = this.formFilter.keyOne
      return true
    },
    refreshFormMyHistoryTask (reloadData = false) {
      if (reloadData) {
        this.formMyHistoryTaskWidget.refreshTable(true, 1)
      } else {
        this.formMyHistoryTaskWidget.refreshTable()
      }
      if (!this.isInit) {
        // 初始化下拉数据
      }
      this.isInit = true
    },
    onFlowDetail (row) {
      let params = {
        processInstanceId: row.processInstanceId
      }

      FlowOperationController.viewInitialHistoricTaskInfo(this, params)
        .then((res) => {
          if (res.data) {
            this.$router.push({
              name: res.data.routerName || 'handlerFlowTask',
              query: {
                processDefinitionKey: row.processDefinitionKey,
                taskId: null,
                processInstanceId: row.processInstanceId,
                processDefinitionId: row.processDefinitionId,
                formId: res.data.formId,
                routerName: res.data.routerName,
                readOnly: true,
                flowEntryName: row.processDefinitionName,
                processInstanceInitiator: row.showName
              }
            })
          }
        })
        .catch((e) => { })
    },
    onResume () {
      this.refreshFormMyHistoryTask()
    },
    initFormData () { },
    formInit () {
      this.refreshFormMyHistoryTask()
    }
  },
  mounted () {
    // 初始化页面数据
    this.formInit()
  }
}
</script>

<style></style>
