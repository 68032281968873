<template>
  <div class="form-single-fragment" style="position: relative">
    <el-form
      label-width="100px"
      :size="defaultFormItemSize"
      label-position="right"
      @submit.native.prevent
    >
      <el-row type="flex">
        <el-form-item label="用户昵称">
          <el-input
            class="filter-item"
            v-model="formSysUser.formFilter.showName"
            @change="refreshFormSysUser(true)"
            :clearable="true"
            placeholder="用户昵称"
          />
        </el-form-item>
        <el-input
          :size="defaultFormItemSize"
          v-model="assignee"
          placeholder="自定义用户"
          style="width: 200px; margin-left: 20px"
        >
        </el-input>
      </el-row>
      <el-row type="flex" justify="end" style="margin-bottom: 15px">
        <el-button
          v-if="showStartUser"
          type="primary"
          :size="defaultFormItemSize"
          @click="setStartUser"
        >
          流程发起人
        </el-button>
        <el-button
          v-if="showAssignee"
          type="primary"
          :size="defaultFormItemSize"
          @click="useAppointedAssignee"
        >
          使用指定审批人
        </el-button>
        <el-button
          type="primary"
          :size="defaultFormItemSize"
          @click="onSubmit"
          :disabled="!canCommit"
        >
          添加用户
        </el-button>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="24">
        <el-radio-group
          class="radio-table"
          v-model="selectUserId"
          style="width: 100%"
        >
          <el-table
            :data="formSysUser.sysUserWidget.dataList"
            :size="defaultFormItemSize"
            height="410px"
            header-cell-class-name="table-header-gray"
            row-key="userId"
            @sort-change="formSysUser.sysUserWidget.onSortChange"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              v-if="multiple"
              header-align="center"
              :reserve-selection="multiple"
              align="center"
              type="selection"
              width="50px"
              :selectable="canSelect"
            />
            <el-table-column
              v-else
              label=""
              header-align="center"
              align="center"
              width="50px"
            >
              <template slot-scope="scope">
                <el-radio :label="scope.row.userId"></el-radio>
              </template>
            </el-table-column>
            <!-- <el-table-column label="用户名" prop="loginName" /> -->
            <el-table-column label="昵称" prop="showName" width="140" />
            <el-table-column
              label="所属部门"
              prop="deptIdDictMap.name"
              width="160"
            />
            <el-table-column label="岗位">
              <template slot-scope="scope">
                <el-tag
                  class="m-r-5"
                  v-for="(item, index) in computeUniquePost(
                    scope.row.sysUserPostObjects
                  )"
                  :key="index"
                  >{{ item }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              label="账号类型"
              prop="userTypeDictMap.name"
              width="140"
            />
            <el-table-column label="创建时间" width="120">
              <template slot-scope="scope">
                <span>{{
                  formatDateByStatsType(scope.row.createTime, "day")
                }}</span>
              </template>
            </el-table-column>
            <template slot="empty">
              <div class="table-empty unified-font">
                <img src="@/assets/img/empty.png" />
                <span>暂无数据</span>
              </div>
            </template>
          </el-table>
          <el-col :span="24">
            <el-row type="flex" justify="end" style="margin-top: 16px">
              <el-pagination
                :total="formSysUser.sysUserWidget.totalCount"
                :current-page="formSysUser.sysUserWidget.currentPage"
                :page-size="formSysUser.sysUserWidget.pageSize"
                :page-sizes="[10, 20, 50, 100]"
                layout="total, prev, pager, next, sizes"
                @current-change="formSysUser.sysUserWidget.onCurrentPageChange"
                @size-change="formSysUser.sysUserWidget.onPageSizeChange"
              >
              </el-pagination>
            </el-row>
          </el-col>
        </el-radio-group>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { SysCommonBizController } from "@/api";
import { statsDateRangeMixin } from "@/core/mixins";
import { findItemFromList } from "@/utils";
import { TableWidget } from "@/utils/widget.js";
export default {
  name: "TaskUserSelect",
  props: {
    // 是否显示指定审批人
    showAssignee: {
      type: Boolean,
      default: true,
    },
    // 是否显示流程发起人
    showStartUser: {
      type: Boolean,
      default: true,
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 已经被使用的用户列表
    usedUserIdList: {
      type: Array,
    },
    // 额外过滤条件
    filterObject: {
      type: Object,
      default: {
        userStatus: 0,
      },
    },
  },
  mixins: [statsDateRangeMixin],
  data() {
    return {
      assignee: undefined,
      // 单选下选中的用户
      selectUserId: undefined,
      // 多选下选中的用户列表
      multiSelectUser: [],
      formSysUser: {
        formFilter: {
          showName: undefined,
        },
        formFilterCopy: {
          showName: undefined,
        },
        sysUserWidget: new TableWidget(
          this.loadSysUserData,
          this.loadSysUserVerify,
          true,
          false
        ),
        isInit: false,
      },
    };
  },
  methods: {
    // 计算唯一岗位
    computeUniquePost(list = []) {
      const postSet = new Set();
      list.map((item) => postSet.add(item.postName));
      return postSet.values();
    },
    onCancel(isSuccess, data) {
      if (this.observer != null) {
        this.observer.cancel(isSuccess, data);
      }
    },
    onClear() {
      this.onCancel(true);
    },
    setStartUser() {
      this.onCancel(true, {
        /* eslint-disable-next-line */
        loginName: "${startUserName}",
      });
    },
    useAppointedAssignee() {
      this.onCancel(true, {
        /* eslint-disable-next-line */
        loginName: "${appointedAssignee}",
      });
    },
    canSelect(row) {
      if (
        Array.isArray(this.usedUserIdList) &&
        this.usedUserIdList.length > 0
      ) {
        return this.usedUserIdList.indexOf(row.loginName) === -1;
      } else {
        return true;
      }
    },
    onSubmit() {
      let selectUser = this.multiSelectUser;
      if (!this.multiple) {
        if (this.assignee != null && this.assignee !== "") {
          this.onCancel(true, {
            loginName: this.assignee,
          });
          return;
        }
        selectUser = findItemFromList(
          this.formSysUser.sysUserWidget.dataList,
          this.selectUserId,
          "userId"
        );
      } else if (this.assignee) {
        this.assignee.split(" ").map((item) => {
          selectUser.push({
            loginName: item,
          });
        });
        this.onCancel(true, selectUser);
        return;
      }
      this.onCancel(true, selectUser);
    },
    handleSelectionChange(values) {
      this.multiSelectUser = values;
    },
    /**
     * 用户管理数据获取函数，返回Primise
     */
    loadSysUserData(params) {
      params.widgetType = "upms_user";
      params.filter = {
        ...this.filterObject,
        showName: this.formSysUser.formFilterCopy.showName,
      };

      return new Promise((resolve, reject) => {
        SysCommonBizController.list(this, params)
          .then((res) => {
            resolve({
              dataList: res.data.dataList,
              totalCount: res.data.totalCount,
            });
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * 用户管理数据获取检测函数，返回true正常获取数据，返回false停止获取数据
     */
    loadSysUserVerify() {
      this.formSysUser.formFilterCopy.showName =
        this.formSysUser.formFilter.showName;
      return true;
    },
    /**
     * 更新用户管理
     */
    refreshFormSysUser(reloadData = false) {
      // 重新获取数据组件的数据
      if (reloadData) {
        this.formSysUser.sysUserWidget.refreshTable(true, 1);
      } else {
        this.formSysUser.sysUserWidget.refreshTable();
      }
      this.formSysUser.isInit = true;
    },
  },
  computed: {
    canCommit() {
      return this.multiple
        ? this.multiSelectUser.length > 0 || this.assignee != null
        : (this.selectUserId != null && this.selectUserId !== "") ||
            this.assignee != null;
    },
  },
  mounted() {
    this.refreshFormSysUser();
  },
};
</script>

<style scoped lang="scss">
.radio-table {
  ::v-deep .el-radio__label {
    display: none;
  }
}
</style>
