<template>
  <div class="form-single-fragment" style="position: relative;">
    <el-form ref="formEditMobileEntry" :model="formData" class="full-width-input" :rules="rules" style="width: 100%;"
             label-width="80px" :size="defaultFormItemSize" label-position="right" @submit.native.prevent>
        <template>
          <el-col :span="24">
            <el-form-item label="可见性" prop="commonEntry">
              <el-radio-group v-model="formData.commonEntry" @change="formData.roleIdList = undefined">
                <el-radio-button :label="true">所有人可见</el-radio-button>
                <el-radio-button :label="false">指定角色可见</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="!formData.commonEntry">
            <el-form-item label="用户角色" prop="roleIdList">
              <el-select v-model="formData.roleIdList" multiple placeholder="绑定用户角色">
                <el-option v-for="role in roleList" :key="role.roleId"
                           :label="role.roleName" :value="role.roleId" />
              </el-select>
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="24">
          <el-row class="no-scroll flex-box" type="flex" justify="end">
            <el-button type="primary" :size="defaultFormItemSize" :plain="true"
                       @click="onCancel(false)">
              取消
            </el-button>
            <el-button type="primary" :size="defaultFormItemSize"
                       @click="onSubmit()">
              保存
            </el-button>
          </el-row>
        </el-col>
    </el-form>
  </div>
</template>

<script>
import { SystemController } from '@/api';
import { FlowEntryController } from '@/api/FlowController';

export default {
  name: 'formEntryAccredit',
  props: {
    entryId: {
      default: undefined
    },
    rowData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        entryId: undefined,
        parentId: undefined,
        entryName: undefined,
        entryType: undefined,
        onlineFlowEntryId: undefined,
        roleIdList: undefined,
        commonEntry: true
      },
      roleList: [],
      rules: {
        roleIdList: [
          {required: true, message: '请选择绑定用户角色', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    onCancel (isSuccess, data) {
      if (this.observer != null) {
        this.observer.cancel(isSuccess, data);
      }
    },
    /**
     * 编辑
     */
    onSubmit () {
      let params = {
        entryId: this.entryId,
        roleIdListString: Array.isArray(this.formData.roleIdList) ? this.formData.roleIdList.join(',') : undefined
      };
      let httpCall = FlowEntryController.accredit(this, params);
      httpCall.then(res => {
        this.$message.success('保存成功');
        this.onCancel(true);
      }).catch(e => {});
    },
    onOnlineFormChange (values) {
      this.formData.onlineFormId = values[1];
    },
    onOnlineEntryChange (values) {
      this.formData.onlineFlowEntryId = values[0];
      this.formData.onlineFormId = values[1];
    },
    loadRole () {
      SystemController.getRoleList(this, {}).then(res => {
        this.roleList = res.data.dataList;
      }).catch(e => {});
    },
    loadFlowEntryRoles () {
      console.log(this.entryId)
      let param = {
        entryId: this.entryId
      }
      FlowEntryController.flowEntryRoleList(this, param).then(res => {
        this.formData.roleIdList = res.data;
        if (res.data.length > 0) {
          this.formData.commonEntry = false;
        }
      }).catch(e => {});
    },
    initFormData () {
      this.loadRole();
      this.loadFlowEntryRoles();
    },
    /**
     * 重置表单数据
     */
    resetFormData () {
      this.formData = {
        entryId: undefined
      }
    },
    formInit () {
      this.initFormData();
    }
  },
  mounted () {
    // 初始化页面数据
    this.formInit();
  }
}
</script>
