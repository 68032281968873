<template>
  <div class="u-rela">
    <!-- 监听器 编辑/创建 部分 -->
      <el-form :size="defaultFormItemSize" :model="listenerFormCopy" label-width="96px" ref="listenerFormRef" @submit.native.prevent>
        <el-form-item label="事件类型" prop="event" :rules="{ required: true, message: '请选择事件类型', trigger: ['blur', 'change'] }">
          <el-select v-model="listenerFormCopy.event">
            <el-option label="start" value="start" />
            <el-option label="end" value="end" />
          </el-select>
        </el-form-item>
        <el-form-item label="监听器类型" prop="listenerType" :rules="{ required: true, message: '请选择监听器类型', trigger: ['blur', 'change'] }">
          <el-select v-model="listenerFormCopy.listenerType">
            <el-option v-for="i in Object.keys(listenerTypeObject)" :key="i" :label="listenerTypeObject[i]" :value="i" />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="listenerFormCopy.listenerType === 'classListener'"
          label="Java类"
          prop="class"
          key="listener-class"
          :rules="{ required: true, message: '请填写Java类', trigger: ['blur', 'change'] }"
        >
          <el-input v-model="listenerFormCopy.class" clearable />
        </el-form-item>
        <el-form-item
          v-if="listenerFormCopy.listenerType === 'expressionListener'"
          label="表达式"
          prop="expression"
          key="listener-expression"
          :rules="{ required: true, message: '请填写表达式', trigger: ['blur', 'change'] }"
        >
          <el-input v-model="listenerFormCopy.expression" clearable />
        </el-form-item>
        <el-form-item
          v-if="listenerFormCopy.listenerType === 'delegateExpressionListener'"
          label="代理表达式"
          prop="delegateExpression"
          key="listener-delegate"
          :rules="{ required: true, message: '请填写代理表达式', trigger: ['blur', 'change'] }"
        >
          <el-input v-model="listenerFormCopy.delegateExpression" clearable />
        </el-form-item>
        <template v-if="listenerFormCopy.listenerType === 'scriptListener'">
          <el-form-item
            label="脚本格式"
            prop="scriptFormat"
            key="listener-script-format"
            :rules="{ required: true, trigger: ['blur', 'change'], message: '请填写脚本格式' }"
          >
            <el-input v-model="listenerFormCopy.scriptFormat" clearable />
          </el-form-item>
          <el-form-item
            label="脚本类型"
            prop="scriptType"
            key="listener-script-type"
            :rules="{ required: true, trigger: ['blur', 'change'], message: '请选择脚本类型' }"
          >
            <el-select v-model="listenerFormCopy.scriptType">
              <el-option label="内联脚本" value="inlineScript" />
              <el-option label="外部脚本" value="externalScript" />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="listenerFormCopy.scriptType === 'inlineScript'"
            label="脚本内容"
            prop="value"
            key="listener-script"
            :rules="{ required: true, trigger: ['blur', 'change'], message: '请填写脚本内容' }"
          >
            <el-input v-model="listenerFormCopy.value" clearable />
          </el-form-item>
          <el-form-item
            v-if="listenerFormCopy.scriptType === 'externalScript'"
            label="资源地址"
            prop="resource"
            key="listener-resource"
            :rules="{ required: true, trigger: ['blur', 'change'], message: '请填写资源地址' }"
          >
            <el-input v-model="listenerFormCopy.resource" clearable />
          </el-form-item>
        </template>
      </el-form>
      <el-divider />
      <p class="listener-filed__title">
        <span><i class="el-icon-menu"></i>注入字段：</span>
        <el-button :size="defaultFormItemSize" type="primary" @click="openListenerFieldForm(null)">添加字段</el-button>
      </p>
      <vxe-table :data="fieldsListOfListenerCopy" :size="defaultFormItemSize" max-height="400" border fit style="flex: none" header-cell-class-name="table-header-gray">
        <vxe-column title="序号" width="50px" type="seq" />
        <vxe-column title="字段名称" min-width="100px" field="name" />
        <vxe-column title="字段类型" min-width="80px" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ fieldTypeObject[scope.row.fieldType] }}
          </template>
        </vxe-column>
        <vxe-column title="字段值/表达式" min-width="200px" show-overflow-tooltip :formatter="row => row.string || row.expression">
          <template slot-scope="scope">
            <div class="white-space">{{ scope.row.string || scope.row.expression }}</div>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="100px">
          <template slot-scope="{ row, $rowIndex }">
            <el-button :size="defaultFormItemSize" type="text" @click="openListenerFieldForm(row, $rowIndex)">编辑</el-button>
            <!-- <el-divider direction="vertical" /> -->
            <el-button :size="defaultFormItemSize" type="text" @click="removeListenerField(row, $rowIndex)">删除</el-button>
          </template>
        </vxe-column>
        <template slot="empty">
          <div class="table-empty unified-font">
            <img src="@/assets/img/empty.png">
            <span>暂无数据</span>
          </div>
        </template>
      </vxe-table>

      <div class="element-drawer__button" style="margin-top: 8px;">
        <el-button :size="defaultFormItemSize" @click="closeDialog">取 消</el-button>
        <el-button :size="defaultFormItemSize" type="primary" @click="saveListenerConfig">保 存</el-button>
      </div>
  </div>
</template>

<script>
import ListenerFieldFormTemplate from './ListenerFieldForm.vue'
import _ from 'lodash'

export default {
  props:{
    listenerForm: {
      type: Object,
      default:()=>({})
    },
    fieldsListOfListener: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      fieldTypeObject:{
        string: "字符串",
        expression: "表达式"
      },
      listenerTypeObject: {
        classListener: "Java 类",
        expressionListener: "表达式",
        delegateExpressionListener: "代理表达式",
        scriptListener: "脚本"
      },
      listenerFormCopy: {},
      fieldsListOfListenerCopy: [],
    }
  },
  created () {
    this.listenerFormCopy =  _.cloneDeep(this.listenerForm)
    this.fieldsListOfListenerCopy = _.cloneDeep(this.fieldsListOfListener)
  },
  methods: {
    // 打开监听器字段编辑弹窗
    openListenerFieldForm(field, index) {
      const temp = field ? JSON.parse(JSON.stringify(field)) : {};
      this.editingListenerFieldIndex = field ? index : -1;
      this.$dialog.show('字段配置', ListenerFieldFormTemplate, {
        area: ['800px', '800px']
      },{
        listenerFieldForm: temp
      }).then(res=>{
        // 编辑
        if (field) {
          this.fieldsListOfListenerCopy.splice(this.editingListenerFieldIndex, 1, res);
          this.listenerFormCopy.fields.splice(this.editingListenerFieldIndex, 1, res);
        } else {
          this.fieldsListOfListenerCopy.push(res);
          this.listenerFormCopy.fields.push(res);
        }
      }).catch(e=>{})
    },
    // 保存监听器配置
    async saveListenerConfig() {
      let validateStatus = await this.$refs["listenerFormRef"].validate();
      if (!validateStatus) return; // 验证不通过直接返回
      if (this.observer != null) {
        this.observer.cancel(true, {
          listenerForm: this.listenerFormCopy,
          fieldsListOfListener: this.fieldsListOfListenerCopy
        });
      }
    },
    closeDialog () {
      if (this.observer != null) {
        this.observer.cancel();
      }
    },
    // 移除监听器字段
    removeListenerField(field, index) {
      this.$confirm("确认移除该字段吗？", "提示", {
        confirmButtonText: "确 认",
        cancelButtonText: "取 消"
      })
        .then(() => {
          this.fieldsListOfListenerCopy.splice(index, 1);
          this.listenerFormCopy.fields.splice(index, 1);
        })
        .catch(() => console.info("操作取消"));
    },
  }
}
</script>

<style scoped lang='scss'>
.white-space{
  white-space: nowrap;
  overflow: hidden;
}
.element-drawer__button{
  position: absolute;
  right: 40px;
  bottom: 40px;
}
</style>
