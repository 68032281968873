<template>
  <div class="data-select">
    <el-select
      :value="selectedValue || ''"
      style="width: 100%"
      :multiple="multiple"
      :disabled="disabled || (relativeTable || {}).relativeFormId == null"
      :size="size"
      :clearable="clearable"
      :placeholder="placeholder"
      :popper-append-to-body="false"
      popper-class="data-select-popper"
      @visible-change="onVisibleChange"
      @clear="onClear"
    >
      <el-option :label="selectedItem.label" :value="selectedItem.value" />
    </el-select>
  </div>
</template>

<script>
import { OnlineFormController } from "@/api/OnlineFormController";
import { dealParams } from "@/Hooks/RelationSelect.js";
import { getUUID } from "@/utils";
import refreshDataMixins from "@/views/thirdParty/refreshDataMixins.js";

import DataSelect from "./dataSelect.vue";

export default {
  name: "JBHDataSelect",
  props: {
    value: {
      type: [String, Number, Array],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    collapseTags: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    relativeTable: {
      type: Object,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    widget: {
      type: Object,
    },
  },
  mixins: [refreshDataMixins],
  inject: ["form"],
  data() {
    return {
      widgetId: getUUID(),
      selectedItem: { label: "", value: "" },
      selectedValue: "",
    };
  },
  methods: {
    refreshData(data) {
      if (data.path === "thirdSelectData/" + this.widgetId && data.isSuccess) {
        this.handlerEditOperate(data.data);
      }
    },
    handlerEditOperate(selectValue) {
      this.selectedItem = {
        label: selectValue[this.relativeTable.displayField],
        value: selectValue[this.relativeTable.primaryKey],
      };
      this.selectedValue = selectValue[[this.relativeTable.primaryKey]];
      this.emitChange(selectValue);
    },
    onVisibleChange(visible) {
      if (!visible || this.form().isEdit) return;
      if (!this.relativeTable.currentDblinkId)
        return this.$message.error(
          "currentDblinkId 为空,请重新配置<弹窗表单选择>"
        );
      let para = {
        dbLinkId: this.relativeTable.currentDblinkId,
        tableName: this.relativeTable.relativeFormId,
        pageParam: {
          pageNum: 1,
          pageSize: 10,
        },
      };
      // 树形结构时 删除分页
      if (this.relativeTable.isTree) {
        delete para.pageParam;
      }
      // 如果有 预设规则 需要添加查询参数
      if (
        Array.isArray(this.relativeTable.preQuery) &&
        this.relativeTable.preQuery.length > 0
      ) {
        const { tableMap, masterTableId } = this.form();
        const { variableName } = tableMap.get(masterTableId).datasource;
        // 关联选择改 组件的tableName 为自定义的
        const tableName = this.relativeTable.relativeFormId;
        // 当关联选择组件在编辑表单中时  无法获取到主表的数据  此时可以从 params 中获取
        const { params, formData } = this.form();
        const filterDtoList = dealParams(
          this.relativeTable.preQuery,
          variableName,
          tableName,
          formData,
          params
        );
        para.filterDtoList = filterDtoList;
      }
      OnlineFormController.listByDbLinkId(this, para)
        .then((res) => {
          let head = res.data.head;
          const columns = Object.freeze(head);
          const showColumns = this.relativeTable.showColumns;
          if (Array.isArray(showColumns) && showColumns.length > 0) {
            head = head.filter((m) => showColumns.includes(m.columnName));
          }
          let dataList = res.data.dataList;
          this.$dialog
            .show(
              "<关联数据选择>",
              DataSelect,
              {
                area: ["80vw", "740px"],
                skin: "one_to_one_query",
              },
              {
                value: this.selectedValue,
                head: head,
                columns: columns, // 原始字段列表
                dataList: dataList.data,
                widget: this.widget,
                relativeTable: this.relativeTable,
                total: dataList.totalCount,
                path: "thirdSelectData/" + this.widgetId,
                preParam: para.filterDtoList || [], // 预设参数
              },
              {
                width: "900px",
                height: "650px",
                pathName: "/thirdParty/thirdSelectData",
              }
            )
            .then((res) => {
              this.handlerEditOperate(res);
            })
            .catch((e) => {});
        })
        .catch(() => {
          this.$message.error("获取弹窗信息失败！");
        });
    },
    onClear() {
      this.selectedItem = { label: "", value: "" };
      this.selectedValue = "";
      this.emitChange(null);
    },
    emitChange(selectRow) {
      this.$emit("input", this.selectedValue);
      this.$emit("change", this.selectedValue, selectRow);
    },
    loadTableData() {
      return new Promise((resolve, reject) => {
        let params = {};
        let httpCall = null;
        params.datasourceId = this.relativeTable.datasourceId;
        params.filterDtoList = [];
        params.filterDtoList.push({
          columnName: this.relativeTable.relativeColumn,
          columnValue: this.selectedValue,
          filterType: 1,
          tableName: this.relativeTable.relativeTableName,
        });
        params.relationId = this.relativeTable.relationId;
        if (!params.datasourceId || !this.relativeTable.variableName)
          return resolve();

        httpCall = this.doUrl(
          `/admin/online/onlineOperation/listByOneToManyRelationId/${this.relativeTable.variableName}`,
          "post",
          params
        );

        httpCall
          .then((res) => {
            if (res.data.dataList.length > 0) {
              this.selectedItem = {
                label: res.data.dataList[0][this.relativeTable.displayField],
                value: this.selectedValue,
              };
            }
            resolve({
              dataList: res.data.dataList,
              totalCount: res.data.totalCount,
            });
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
  mounted() {
    this.selectedValue = this.value;
  },
  watch: {
    value: {
      handler(n) {
        this.selectedValue = this.value;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.data-select {
  ::v-deep {
    .data-select-popper {
      display: none;
    }

    .el-dialog__header {
      height: 42px;
      line-height: 42px;
      padding: 0 20px; // Shorthand for 0px 20px
      background-color: #f8f8f8;
    }

    .el-dialog__title {
      color: #333;
      font-size: 14px;
    }

    .el-dialog__headerbtn {
      top: 12px;
    }

    .el-dialog__body {
      padding: 25px;
    }
  }
}
</style>
