<template>
  <div class="u-rela wrapper">
    <!-- 注入字段 编辑/创建 部分 -->
      <el-form :model="listenerFieldFormCopy" :size="defaultFormItemSize" label-width="96px" ref="listenerFieldFormRef" @submit.native.prevent>
        <el-form-item label="字段名称：" prop="name" :rules="{ required: true, message: '请填写字段名称', trigger: ['blur', 'change'] }">
          <el-input v-model="listenerFieldFormCopy.name" clearable />
        </el-form-item>
        <el-form-item label="字段类型：" prop="fieldType" :rules="{ required: true, message: '请选择字段类型', trigger: ['blur', 'change'] }">
          <el-select v-model="listenerFieldFormCopy.fieldType">
            <el-option v-for="i in Object.keys(fieldTypeObject)" :key="i" :label="fieldTypeObject[i]" :value="i" />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="listenerFieldFormCopy.fieldType === 'string'"
          label="字段值："
          prop="string"
          key="field-string"
          :rules="{ required: true, message: '请填写字段值', trigger: ['blur', 'change'] }"
        >
          <scriptEdit v-model="listenerFieldFormCopy.string" type="json" style="width: 600px;height: 550px;"></scriptEdit>
        </el-form-item>
        <el-form-item
          v-if="listenerFieldFormCopy.fieldType === 'expression'"
          label="表达式："
          prop="expression"
          key="field-expression"
          :rules="{ required: true, message: '请填写表达式', trigger: ['blur', 'change'] }"
        >
          <scriptEdit v-model="listenerFieldFormCopy.expression" style="width: 600px;height: 350px;"></scriptEdit>
        </el-form-item>
      </el-form>
      <div class="u-abso btns">
        <el-button :size="defaultFormItemSize" @click="closeDialog">取 消</el-button>
        <el-button :size="defaultFormItemSize" type="primary" @click="saveListenerFiled">确 定</el-button>
      </div>
  </div>
</template>

<script>
import scriptEdit from '@/components/ScriptEditor/index.vue'
import _ from 'lodash'
export default {
  props:{
    listenerFieldForm:{
      type: Object,
      default: () => ({})
    }
  },
  components: {
    scriptEdit
  },
  data () {
    return {
      fieldTypeObject: {
        string: "字符串",
        expression: "表达式"
      },
      listenerFieldFormCopy: {}
    }
  },
  created () {
    this.listenerFieldFormCopy = _.cloneDeep(this.listenerFieldForm)
  },
  methods: {
    closeDialog () {
      if (this.observer != null) {
        this.observer.cancel();
      }
    },
    // 保存监听器注入字段
    async saveListenerFiled() {
      let validateStatus = await this.$refs["listenerFieldFormRef"].validate();
      if (!validateStatus) return; // 验证不通过直接返回
      if (this.observer != null) {
        this.observer.cancel(true, this.listenerFieldFormCopy);
      }
    },
  }
}
</script>

<style scoped lang='scss'>
.u-rela{
  position: relative;
}

.u-abso{
  position: absolute;
}
.btns{
  right: 0px;
  bottom: 0px;
}
.wrapper{
  height: 100%;
}
</style>
