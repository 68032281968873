var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-draggable",style:({
    'margin-bottom':
      (_vm.widget.props.paddingBottom ||
        (_vm.widget.props.basicInfo || {}).paddingBottom ||
        0) + 'px',
  })},[((_vm.widget.childWidgetList || []).length > 0)?_c('el-tabs',{staticClass:"p-x-10",attrs:{"type":_vm.widget.props.type},model:{value:(_vm.activePanel),callback:function ($$v) {_vm.activePanel=$$v},expression:"activePanel"}},_vm._l((_vm.widget.childWidgetList),function(subWidget){return _c('el-tab-pane',{key:subWidget.variableName,attrs:{"label":subWidget.showName,"name":subWidget.variableName,"lazy":true}},[_c('OnlineCustomBlock',{attrs:{"isEdit":_vm.isEdit},on:{"widgetClick":_vm.onWidgetClick},model:{value:(subWidget.childWidgetList),callback:function ($$v) {_vm.$set(subWidget, "childWidgetList", $$v)},expression:"subWidget.childWidgetList"}})],1)}),1):_vm._e(),(_vm.isEdit && (_vm.widget.childWidgetList || []).length <= 0)?_c('div',{staticClass:"info",staticStyle:{"border":"1px solid #e8eaec"}},[_vm._m(0),_c('span',[_vm._v("请添加标签页")])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100px","width":"100px"}},[_c('i',{staticClass:"el-icon-upload"})])
}]

export { render, staticRenderFns }