<template>
  <div class="panel-tab__content" style="padding-top: 3px">
    <vxe-table :data="elementListenersList" :size="defaultFormItemSize" header-cell-class-name="table-header-gray">
      <vxe-column title="序号" width="50px" type="seq" />
      <vxe-column title="事件类型" min-width="100px" field="event" />
      <vxe-column title="监听器类型" min-width="100px" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ listenerTypeObject[scope.row.listenerType] }}
        </template>
      </vxe-column>
      <vxe-column title="操作" width="90px">
        <template slot-scope="{ row, $index }">
          <el-button :size="defaultFormItemSize" type="text" @click="openListenerForm(row, $index)">编辑</el-button>
          <el-button :size="defaultFormItemSize" type="text" @click="removeListener(row, $index)">删除</el-button>
        </template>
      </vxe-column>
      <template slot="empty">
        <div class="table-empty unified-font">
          <img src="@/assets/img/empty.png">
          <span>暂无数据</span>
        </div>
      </template>
    </vxe-table>
  </div>
</template>
<script>
import { createListenerObject, updateElementExtensions } from "../../utils";
import { initListenerType, initListenerForm } from "./utilSelf";
import editListener from './CreateListener.vue'
export default {
  name: "ElementListeners",
  props: {
    id: String,
    type: String
  },
  inject: {
    prefix: "prefix",
    width: "width"
  },
  data() {
    return {
      elementListenersList: [], // 监听器列表
      listenerForm: {}, // 监听器详情表单
      listenerFormModelVisible: false, // 监听器 编辑 侧边栏显示状态
      fieldsListOfListener: [],
      listenerFieldFormModelVisible: false, // 监听器 注入字段表单弹窗 显示状态
      editingListenerIndex: -1, // 监听器所在下标，-1 为新增
      listenerTypeObject: {
        classListener: "Java 类",
        expressionListener: "表达式",
        delegateExpressionListener: "代理表达式",
        scriptListener: "脚本"
      },
      fieldTypeObject: {
        string: "字符串",
        expression: "表达式"
      }
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        val && val.length && this.$nextTick(() => this.resetListenersList());
      }
    }
  },
  methods: {
    getOtherExtensionList () {
      this.otherExtensionList = [];
      let temp = this.bpmnElement.businessObject?.extensionElements?.values?.filter(ex => {
        if (ex.$type !== `${this.prefix}:ExecutionListener`) {
          this.otherExtensionList.push(ex);
        }
        return ex.$type === `${this.prefix}:ExecutionListener`;
      }) ?? [];
      return temp;
    },
    resetListenersList() {
      this.bpmnElement = window.bpmnInstances.bpmnElement;
      this.otherExtensionList = [];
      this.bpmnElementListeners = this.getOtherExtensionList();
      this.elementListenersList = this.bpmnElementListeners.map(listener => initListenerType(listener));
    },
    // 打开 监听器详情
    openListenerForm(listener, index) {

      if (listener) {
        if (Array.isArray(listener.fields)) {
          listener.fields.forEach(m => {
            m.fieldType = m.string ? 'string' : 'expression'
          })
        }
        this.listenerForm = initListenerForm(listener);
        this.editingListenerIndex = index;
      } else {
        this.listenerForm = {};
        this.editingListenerIndex = -1; // 标记为新增
      }


      if (listener && listener.fields) {
        this.fieldsListOfListener = listener.fields.map(field => ({ ...field, fieldType: field.string ? "string" : "expression" }));
      } else {
        this.fieldsListOfListener = [];
        this.$set(this.listenerForm, "fields", []);
      }


      this.$dialog.show('执行监听器', editListener, {
        area: ['1000px', '800px']
      },
      {
        listenerForm: this.listenerForm,
        fieldsListOfListener: this.fieldsListOfListener
      }).then(({listenerForm,fieldsListOfListener})=>{
        this.fieldsListOfListener = fieldsListOfListener
        const listenerObject = createListenerObject(listenerForm, false, this.prefix);
        if (this.editingListenerIndex === -1) {
          this.bpmnElementListeners.push(listenerObject);
          this.elementListenersList.push(listenerForm);
        } else {
          this.bpmnElementListeners.splice(this.editingListenerIndex, 1, listenerObject);
          this.elementListenersList.splice(this.editingListenerIndex, 1, listenerForm);
        }
        this.getOtherExtensionList();
        updateElementExtensions(this.bpmnElement, this.otherExtensionList.concat(this.bpmnElementListeners));

      }).catch(()=>{})

      // this.$nextTick(() => {
      //   if (this.$refs["listenerFormRef"]) this.$refs["listenerFormRef"].clearValidate();
      // });
      // // 打开侧边栏并清楚验证状态
      // this.listenerFormModelVisible = true;

    },


    // 移除监听器
    removeListener(listener, index) {
      this.$confirm("确认移除该监听器吗？", "提示", {
        confirmButtonText: "确 认",
        cancelButtonText: "取 消"
      })
        .then(() => {
          this.getOtherExtensionList();
          this.bpmnElementListeners.splice(index, 1);
          this.elementListenersList.splice(index, 1);
          updateElementExtensions(this.bpmnElement, this.otherExtensionList.concat(this.bpmnElementListeners));
        })
        .catch(() => console.info("操作取消"));
    },
  }
};
</script>
