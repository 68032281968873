import { render, staticRenderFns } from "./TaskMultipleSelect.vue?vue&type=template&id=6f90477c&scoped=true"
import script from "./TaskMultipleSelect.vue?vue&type=script&lang=js"
export * from "./TaskMultipleSelect.vue?vue&type=script&lang=js"
import style0 from "./TaskMultipleSelect.vue?vue&type=style&index=0&id=6f90477c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@6.11.0_ejs@3.1.10_lodash@4.17.21_vue-template_u4bif2bh4bd4hzduv4pijfcrou/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f90477c",
  null
  
)

export default component.exports