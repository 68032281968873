var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-container-wrapper",attrs:{"table-key":_vm.keyName}},[_vm._m(0),_c('div',{ref:"myTableWrapper",staticClass:"widget-table-wrapper",style:({ background: _vm.widget.props.backgroundColor }),on:{"contextmenu":_vm.showContextMenu}},[(_vm.index.length > 0)?[_c('table',{ref:"myTable",staticClass:"widget-table",attrs:{"cellspacing":"0","cellpadding":"0","border":"0"}},_vm._l((_vm.table.render),function(rV,rK){return _c('tr',{key:rK},_vm._l((rV),function(cV,cI){return _c('td',{key:cI,staticClass:"table-container-cell",class:{
              selectTdClass:
                cV.rowIndex === _vm.current.rowIndex &&
                cV.colIndex === _vm.current.colIndex &&
                _vm.isEdit,
            },style:({
              border: '1px solid ' + _vm.widget.props.borderColor,
              backgroundColor: cV.color,
            }),attrs:{"title":cV.index,"row":cV.rowIndex,"col":cV.colIndex,"rowspan":cV.rowSpan,"colspan":cV.colSpan,"align":cV.align,"valign":cV.valign,"width":cV.width},on:{"click":function($event){return _vm.setSource(cV)}}},[_c('div',{staticClass:"widget-td-wrapper"},[(_vm.bindWidgetList(cV.rowIndex, cV.colIndex))?[_c('OnlineCustomBlock',{staticClass:"cell-custom-block",attrs:{"isEdit":_vm.isEdit},on:{"dragAdd":_vm.onDragAdd,"widgetClick":_vm.onWidgetClick},model:{value:(
                    _vm.widget.childWidgetList[cV.rowIndex].childWidgetList[
                      cV.colIndex
                    ].childWidgetList
                  ),callback:function ($$v) {_vm.$set(_vm.widget.childWidgetList[cV.rowIndex].childWidgetList[
                      cV.colIndex
                    ], "childWidgetList", $$v)},expression:"\n                    widget.childWidgetList[cV.rowIndex].childWidgetList[\n                      cV.colIndex\n                    ].childWidgetList\n                  "}})]:_vm._e()],2)])}),0)}),0)]:_vm._e()],2),_c('div',{ref:"tableMenu",staticClass:"menu",on:{"click":_vm.hideContextMenu}},[_c('div',{staticClass:"menu__item",staticStyle:{"line-height":"30px","cursor":"pointer"},on:{"mousedown":_vm.insertRows}},[_vm._v(" 向下插入行 ")]),_c('div',{staticClass:"menu__item",staticStyle:{"line-height":"30px","cursor":"pointer"},on:{"mousedown":_vm.insertColumns}},[_vm._v(" 向右插入列 ")]),(_vm.allows.deleteRow)?_c('div',{staticClass:"menu__item",staticStyle:{"line-height":"30px","cursor":"pointer"},on:{"mousedown":_vm.deleteRows}},[_vm._v(" 删除行 ")]):_vm._e(),(_vm.allows.deleteColumn)?_c('div',{staticClass:"menu__item",staticStyle:{"line-height":"30px","cursor":"pointer"},on:{"mousedown":() => _vm.deleteColumns()}},[_vm._v(" 删除列 ")]):_vm._e(),(_vm.allows.mergeRightColumn)?_c('div',{staticClass:"menu__item",staticStyle:{"line-height":"30px","cursor":"pointer"},on:{"mousedown":_vm.mergeRightColumn}},[_vm._v(" 向右合并 ")]):_vm._e(),(_vm.allows.mergeDownRow)?_c('div',{staticClass:"menu__item",staticStyle:{"line-height":"30px","cursor":"pointer"},on:{"mousedown":_vm.mergeDownRow}},[_vm._v(" 向下合并 ")]):_vm._e(),(_vm.allows.splitCells)?_c('div',{staticClass:"menu__item",staticStyle:{"line-height":"30px","cursor":"pointer"},on:{"mousedown":_vm.splitCells}},[_vm._v(" 拆分单元格 ")]):_vm._e(),(_vm.allows.mergeCells)?_c('div',{staticClass:"menu__item",staticStyle:{"line-height":"30px","cursor":"pointer"},on:{"mousedown":_vm.mergeCells}},[_vm._v(" 合并单元格 ")]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"active-widget-drag"},[_c('i',{staticClass:"el-icon-rank"})])
}]

export { render, staticRenderFns }