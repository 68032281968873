<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  watch: {
    $route: {
      handler (newValue) {
        document.title = process.env.VUE_APP_PROJECT_NAME;
        if (newValue.meta && newValue.meta.title) document.title += ' - ' + newValue.meta.title;
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.debug{
  border: 1px solid red !important;
}
.debug-blue{
  border: 1px solid skyblue;
}
.debug-info {
  color: #999;
  font-size: 10px;
}
.w-full {
  width: 100%;
}
</style>
