
<template>
  <div class="form-single-fragment" style="position: relative;">
    <el-form label-width="80px" label-position="right" style="border-bottom: 1px solid #ddd;">
      <el-form-item label="品牌">
        <div class="rela brand-wrapper brands">
          <div class="flex gap-20 flex-wrap brands" :class="{ hidden: !showallBrands }" style="width: calc(100% - 100px);">
            <div id="brands-wrapper" class="select-items" @click="checkBrand(item)"
              :class="{ actived: currentBrand == item.value }" v-for="item in brands" :key="item.id">
                {{ item.label }}
            </div>
          </div>
          <span v-if="showmore" class="more-button" @click="showallBrands = !showallBrands">
            更多
            <i class="el-icon-arrow-up" :class="{ rotate: showallBrands }"></i>
          </span>
        </div>
      </el-form-item>
      <el-form-item label="采购类型">
        <div class="flex gap-20">
          <div class="select-items" @click="currentType = item.value" :class="{ actived: currentType == item.value }"
            v-for="item in types" :key="item.id">{{ item.name }}</div>
        </div>
      </el-form-item>
      <el-row>
        <el-col :span="5">
          <el-form-item label="产品目录">
            <el-cascader v-model="query.indexId" :props="props"></el-cascader>
          </el-form-item>
        </el-col>
<!--        {{suspliers}}-->
<!--        <el-col :span="4">-->
<!--          <el-form-item label="供应商" class="m-l-20">-->
<!--            <el-select v-model="query.supplierNo">-->

<!--              <el-option v-for="item in suspliers" :key="item.id" :label="item.label" :value="item.value"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="4">
          <el-form-item label="价格范围">
            <div class="flex">
              <el-input type="number" v-model="query.min" style="width: 100px;" disabled></el-input>
              <span class="m-x-12">-</span>
              <el-input type="number" v-model="query.max" style="width: 100px;" disabled></el-input>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="关键词" class="m-l-20">
            <el-input v-model="query.merchandiseName" placeholder="请输入商品关键字"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3" :offset="2">
          <el-button type="primary" @click="queryProduct">查询</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!-- 样品列表 -->
    <div class="wrapper p-t-20">
      <el-row :gutter="10">
        <el-col :span="4" v-for="item in sampleList" :key="item.id">
          <product-item :product="item" :check="item.checked" checkable @onChange="onSelectChange" @showDtl="showDtl"></product-item>
        </el-col>
      </el-row>
    </div>
      <div style="padding-top: 10px;" class="flex flex-end">
          <el-pagination hide-on-single-page background layout="prev, pager, next,total" :total="total" :page-size="query.size" @current-change="current_change"></el-pagination>
      </div>
    <el-form ref="selectedProduct" class="full-width-input p-y-20" style="width: 100%;" label-width="100px"
      :size="defaultFormItemSize" label-position="right" @submit.native.prevent>
      <el-row :gutter="20">
      </el-row>
      <el-col :span="24">
        <el-row class="no-scroll flex-box" type="flex" justify="end">
          <el-button type="primary" :size="defaultFormItemSize" :plain="true" @click="onCancel(false)">
            取消
          </el-button>
          <el-button type="primary" :size="defaultFormItemSize" @click="onSaveClick()">
            保存
          </el-button>
        </el-row>
      </el-col>
    </el-form>
    <ProductDetail ref="ProductDetail"></ProductDetail>
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { uploadMixin, statsDateRangeMixin } from '@/core/mixins';
import productItem from '../components/ProductItem.vue'
import ProductDetail from '../components/ProductDetail.vue'

export default {
  name: 'selectedProduct',
  components: { productItem, ProductDetail },
  props: {
    rowData: {
      type: Object
    },
    /** 当前已经选择的产品 */
    currentSelected: {
      type: Array,
      default: () => []
    }
  },
  mixins: [uploadMixin, statsDateRangeMixin],
  data () {
    return {
      props: {
        lazy: true,
        lazyLoad: this.getBrand
      },
      types: [
        { id: 0, name: '全部', value: 0 },
        { id: 1, name: '内采', value: '01' },
        { id: 2, name: '外采', value: '02' }
      ],
      suspliers: [], // 供应商
      currentType: 0,
      query: {
        current: 1,
        size: 18
      },
      total: 0,
      brands: [],
      selectedBrands: [],
      currentBrand: 0,
      showmore: true,
      showallBrands: false, // 是否显示全部品牌
      showallPurchaseType: true,
      merchandiseProductList: [],
      sampleList: [],
      selectedProducts: [] // 已选择的样品
    }
  },
  methods: {
    current_change (currentPage) {
      this.query.current = currentPage;
      this.initProductList();
    },
    queryProduct () {
      this.query.current = 1;
      this.initProductList();
    },
    // 显示商品详情
    showDtl (product) {
      this.$refs.ProductDetail.open(product)
    },
    /** id  样品id  bool 是否选择 */
    onSelectChange (id, bool) {
      let tempData = new Set(this.selectedProducts)
      if (bool) tempData.add(id)
      else tempData.delete(id)
      this.selectedProducts = [...tempData]
    },
    checkBrand (item) {
      this.currentBrand = item.value
      this.query.brand = item.value
    },
    onCancel (isSuccess, data) {
      if (this.observer != null) {
        this.observer.cancel(isSuccess, data);
      }
    },
    onSaveClick () {
      // 选择的商品
      const samples = this.sampleList.filter(m => this.selectedProducts.includes(m.merchandiseId))
      this.onCancel(true, samples);
    },
    /** 获取一级类目 */
    getBrand (node, resolve) {
      if (node.level === 0) {
        // 判断有无缓存
        if (this.$store.state.app.category.length === 0) {
          console.log('没有缓存, 请求api')
          this.doUrl('/admin/online/onlineDict/listLevelDict', 'post', {
            dbLinkId: '1681858012760772694',
            tableName: 'mmc_category'
          }).then(res => {
            const temp = res.data.map(m => ({
              id: m.id,
              value: m.id,
              label: m.name
            }))
            // 缓存一级类目 防止频繁请求
            this.$store.dispatch('app/saveCategory', temp)
            resolve(temp)
          })
        } else {
          console.log('直接读取缓存')
          resolve(this.$store.state.app.category)
        }
      } else {
        this.doUrl('/admin/online/onlineDict/listLevelDict', 'post', {
          dbLinkId: '1681858012760772694',
          tableName: 'mmc_category',
          id: node.value
        }).then(res => {
          console.log(res)
          const temp = res.data.map(m => ({
            id: m.id,
            value: m.id,
            label: m.name
          }))
          resolve(temp)
        })
      }
    },
    /** 初始化 品牌列表 */
    initBrands () {
      return new Promise((resolve, reject) => {
        if (this.$store.state.app.brands.length > 0) {
          this.brands = this.$store.state.app.brands
          return resolve()
        }
        this.doUrl('/admin/business/dict/brandList', 'post', {}).then(res => {
          const temp = res.data.map(m => ({
            id: m.dictValue,
            label: m.dictName,
            value: m.dictValue
          }))
          temp.unshift({id: 0, value: null, label: '全部'})
          // 缓存
          this.$store.dispatch('app/saveBrands', temp)
          this.brands = temp
          resolve()
        }).catch(() => reject())
      })
    },
    /** 初始化 供应商列表 */
    initSuspliers () {
      return new Promise((resolve, reject) => {
        if (this.$store.state.app.supplier.length > 0) {
          this.suspliers = this.$store.state.app.supplier
          return resolve()
        }
        this.doUrl('/admin/business/dict/supplierList', 'post', {}).then(res => {
          const temp = res.data.map(m => ({
            id: m.dictValue,
            value: m.dictValue,
            label: m.dictName
          }))
          this.$store.dispatch('app/saveSupplier', temp)
          this.suspliers = temp
          resolve()
        }).catch(() => reject())
      })
    },
    /** 初始化查询商品 */
    initProductList () {
      this.doUrl('/admin/business/mmcMerchandise/list', 'post', {
        mmcMerchandiseDtoFilter: {
          supplierNo: this.query.supplierNo,
          brandId: this.currentBrand ? this.currentBrand : null,
          merchandiseName: this.query.merchandiseName,
          category: this.query.indexId,
          purchaseType: this.currentType ? this.currentType : null
        },
        pageParam: {
          pageNum: this.query.current,
          pageSize: this.query.size
        }
      }).then(res => {
        // res.data.dataList
        const result = res.data.dataList
        const totalCount = res.data.totalCount
        if (this.currentSelected.length > 0) {
          result.forEach(item => {
            if (this.currentSelected.includes(item.merchandiseId)) item.checked = true
          })
        }
        this.sampleList = result
        this.total = totalCount
      })
    }
  },
  created () {
    this.selectedProducts = this.currentSelected
  },
  mounted () {
    // 初始化页面数据
    // 初始化 品牌
    // 初始化 供应商
    const arr = [this.initBrands(), this.initSuspliers()]

    Promise.all(arr).then(() => {
      // 根据条件 查询样品列表
      this.initProductList()
    })
  }
}
</script>

<style lang="scss" scoped>
.wrapper{
  height: calc(90vh - 320px);
  overflow: scroll;
  overflow-x: hidden;
}
.gap-20 {
  gap: 20px;
}

.select-items {
  color: rgba(102, 102, 102, 1);
  background-color: #f0f2f6;
  padding: 0 20px;
  line-height: 2;
  cursor: pointer;
  box-sizing: border-box;
}

.brands{
  transition: all 1s;
}
.rotate {
  rotate: 180deg;
}

.el-icon-arrow-up {
  transition: all .2s;
}

.hidden {
  max-height: 40px;
  overflow: hidden;
}

.actived {
  background-color: #2a9ffb;
  color: white;
}

.rela {
  position: relative;
}

.more-button {
  position: absolute;
  right: 20px;
  top: -5px;
  cursor: pointer;
  user-select: none;
}
</style>
